import React from "react";
import {Card, Typography, MenuItem, Select } from '@mui/material'
import { url } from "../../../globalurl";
import axios from "axios";
import PanToolIcon from '@mui/icons-material/PanTool';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { Snackbar, Alert } from '@mui/material';

let config = {
    headers : {
        "Content-type": "Application/json",
        "Authorization" : `Bearer ${localStorage.getItem('token')}`
    },
    withCredentials : true
}

class Operator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            templateList : [],
            template : "none",
            status : "#FF0000",
            job : false,
            lastProduct : "",
            lastProductUrl : "", 
            mac : "",
            alertMsg : null,
            alert : false,
            severity : null,
            jobStatus : 0
        }
    }

    componentDidMount = () => {
        this.handleGetTemplates()
        // this.handleJobCheck()
        this.getMac()
        
    }

    componentWillUnmount = () => {
        this.handleJob()
        clearInterval(this.interval)
        this.interval = null
    }

    handleStopJob = (mac) => {
        let data = {
            mac : mac
        }
        axios.post(`${url}/operator/stop`, data, config).then((res) => {
            if (res.status === 200) {
                if (res.data.status) {
                    this.setState({job : false})
                } 
            }
        })
    }

    handleGetTemplates = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.get(`${url}/template`, config).then((res) => {
            if (res.status === 200) {
                this.setState({templateList : res.data})
            }
        })
    }

    getMac = () => {
        axios.get(`http://localhost:8000/mac`).then((res) => {
            if (res.status === 200) {
                this.setState({mac : res.data.mac})
                this.handleStopJob(res.data.mac)
                this.interval = setInterval(this.handleJobCheck, 800)
                
            }
        })
    }

    handleJobCheck = async () => {
        if (this.state.mac === "") {
            this.setState({alert : true, alertMsg : "System did not Match!!", severity: "error"})
        } else if (this.state.template === "none") {
            this.setState({alert : true, alertMsg : "Please Select Template", severity: "error"})
        } else {
            let data = {
                mac : this.state.mac
            }
            await axios.post(`${url}/operator`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        this.setState({jobStatus : res.data.operator[0].status, job : res.data.operator[0].jobStatus === 0 ? false : true})
                    } else {
                        this.setState({jobStatus : 0, job : false})
                    }
                }
            }).then(() => {

                axios.post(`${url}/operator/last_photo`, data, config).then((response) => {
                    if (response.status === 200) {
                        if (response.data.photo_path !== this.state.lastProduct) {
                            this.setState({lastProduct : response.data.photo_path})
                            this.handleImageSrc(response.data.photo_path)
                        }
                    }
                })
            })
        }
    }

    handleImageSrc = (data) => {
        
        let image = ""
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        let formData = new FormData()
        formData.append('photo_name', data)
        axios({
            url : `${url}/download`,
            data : formData,
            method : "POST",
            headers : config.headers,
            withCredentials : true,
            responseType : "blob"
        }).then((response) => {
            if (response.status === 200) {
                image = URL.createObjectURL(new Blob([response.data], {type: 'image/jpg'}));
                this.setState({lastProductUrl : image})
            }
        })
    }

    handleJob = () => {
        this.handleJobCheck()
        if (this.state.mac !== "" && !this.state.job && this.state.template !== "none") {
            let data = {
                mac : this.state.mac,
                template_id : this.state.template
            }
            axios.post(`${url}/operator/start`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({job : true,})
                    } else {
                        this.setState({job : false})
                    }
                }
            })
        } else if (this.state.job) {
            let data = {
                mac : this.state.mac,
            }
            axios.post(`${url}/operator/stop`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({job : false})
                    } 
                }
            })
        }
    }

    render() {
        return (
            <Card style={{display:'flex', flex:1, padding:20, flexDirection:'column'}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div style={{display:'flex', alignItems:'center', gap:50}}>
                        <Typography style={{display:'flex', justifyContent:'flex-end'}} >Select Template</Typography>
                        <Select
                            disabled={this.state.job}
                            value={this.state.template}
                            style={{width:256}}
                            defaultValue="none"
                            onChange={(e) => this.setState({template : e.target.value})}
                        >
                            <MenuItem disabled value={"none"}>Select Template</MenuItem>
                            {this.state.templateList.map((k,i) => 
                                <MenuItem key={k.name} value={k.id}>{k.name}</MenuItem>
                            )}
                        </Select>
                    </div>
                    <div style={{display:'flex', gap:20}}>
                        <Typography>Status</Typography>
                        <div style={{width:24, height:24, borderRadius:24, backgroundColor: this.state.jobStatus === 0 ? "#FF0000" : "#008000"}}></div>
                    </div>
                </div>
                <div 
                    onClick={this.handleJob}
                    style={{boxShadow:"0 3px 6px #00000012", maxWidth:'max-content', padding:'10px 50px', marginTop:20, borderRadius:8, cursor:'pointer'}}>
                    {this.state.job ? 
                        <div style={{display:'flex'}}>
                            <Typography>STOP JOB</Typography>
                            <PanToolIcon />
                            
                        </div>
                        :
                        <div style={{display:'flex'}}>
                            <Typography>START JOB</Typography>
                            <PlayArrowIcon />
                        </div>
                    }
                </div>
                <Card style={{flex:1, padding:10, cursor:'pointer', marginTop:40}}>
                    <Typography>Product Image</Typography>
                    <img src={this.state.lastProductUrl} style={{width:'inherit', minHeight:430, borderRadius:5}} />
                </Card>
                <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.setState({alert : false})}>
                    <Alert elevation={6} onClose={() => this.setState({alert : false})} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </Card>
        )
    }
}

export default Operator