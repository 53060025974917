import React from "react";
import { Typography, Button } from "@mui/material";
import {url} from '../../../../globalurl'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";

class ViewStyle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            styleName : '',
            path : "",
            alert : false,
            alertMsg : null,
            severity : null,
        }
        
    }

    componentDidMount = () => {
        this.setState({styleName : this.getter('styleName'), path : this.getter('stylePath')})
        this.handleDownloadImg()
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    handleDownloadImg = () => {
        axios({
            url : `${url}/style?id=${this.state.data.id}`,
            method : 'GET',
            withCredentials : true,
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            responseType : 'blob'
        }).then((response) => {
            let url = URL.createObjectURL(new Blob([response.data], {type: 'image/svg+xml'}));
            this.setState({path : url}) 
        })
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30, minHeight:400}}>
                <Typography>{this.state.styleName}</Typography>
                
                <img src={this.state.path} width={250} />
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default ViewStyle