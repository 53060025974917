import React from "react";
import ReactDOM from 'react-dom'
import {Card, Pagination, Divider, Box, Typography, IconButton, Dialog, DialogContent, DialogTitle, Snackbar, Alert, TextField, Button, MenuItem,
    Select
} from '@mui/material';
import { url } from "../../../globalurl";
import axios from "axios";

import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CloseIcon from '@mui/icons-material/Close';

import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import './prodComp/product.css'
import { Link } from "react-router-dom";

import QRCode from "react-qr-code";
import BarCode from 'react-barcode';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';

import { Navigate } from "react-router-dom";
import EditProduct from "./prodComp/EditProduct";
import DeleteProduct from "./prodComp/DeleteProduct";

import Canvg, {
    presets
} from 'canvg';
// add width to variable
const sw = window.innerWidth

class Products extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            productList : [],
            numberOfPage : 0,
            itemsPerPage : 10,
            page : 1,
            imageUrl : [],
            templateList : [],
            template : "",
            batchCode : "",
            selectionRange : [{
                startDate: "",
                endDate: "",
            }],
            fullWidth : false,
            fullWidthRenderData : {},
            fullWidthStyleUrl : "",
            redirect : false,
            imgFull : false,
            imgFullSrc : "",
            alertMsg : null,
            alert : false,
            severity : null,
            open : false,
            label : '',
            component : ""
        }
        this.productContainer = React.createRef() 
    }

    componentDidMount = () => {
        if (Object.keys(this.state.fullWidthRenderData).length === 0 && this.state.fullWidth) {
            this.setState({fullWidth : false, redirect : true})  
        }
        this.handleProductList()
        this.handleGetTemplates()
    }

    handleDownloadStyle = (data) => {
        axios({
            url : `${url}/style?id=${data.style}`,
            method : 'GET',
            withCredentials : true,
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            // responseType : 'blob'
        }).then((response) => {
            console.log(response)
            let url = URL.createObjectURL(new Blob([response.data], {type: 'image/svg+xml'}));
            let element = document.getElementById("render-style")
            element.innerHTML = response.data
            element.firstChild.removeAttribute('width')
            element.firstChild.removeAttribute('height')
            this.handleLabelGenerate(element, this.state.fullWidthRenderData)
            this.setState({fullWidthStyleUrl : response.data}) 
        })
    }

    handleLabelGenerate = (el, data) => {
        el.querySelector('#pcs').firstChild.innerHTML = data.ctnPcs
        el.querySelector('#measurement').firstChild.innerHTML = data.measurements
        el.querySelector('#gross-weight').firstChild.innerHTML = data.grossWeight
        el.querySelector('#date-code').firstChild.innerHTML = data.dataCode
        el.querySelector('#batch-code').firstChild.innerHTML = data.batchCode
        el.querySelector('#made-in').firstChild.innerHTML = data.madeIn
        el.querySelector('#item-number').firstChild.innerHTML = data.itemCode

        //  Handle creation of qr code
        let qrCodeValue = `http://search.piar.ee/#/${data.qrCode}`
        let qrCodeEl = <QRCode value={qrCodeValue} includeMargin={true} />;
        let tempDom = document.createElement('div')
        ReactDOM.render(qrCodeEl, tempDom)
        const blob = new Blob([tempDom.innerHTML], {type: 'image/svg+xml'});
        const url = URL.createObjectURL(blob);
        el.querySelector('#qr-code').setAttribute('xlink:href', url)
        el.querySelector('#qr-code').setAttribute('height', 32)
        el.querySelector('#qr-code').setAttribute('width', 32)
        el.querySelector('#qr-code').setAttribute('x', 2.5)
        el.querySelector('#qr-code').setAttribute('y', 2.5)

        // Handle creation of bar code
        let barCodeEl = <BarCode 
            height = {30}
            format="EAN13"
            flat={true}
            fontSize= {20} 
            value={parseInt(data.barCode)} />;
        let barTempDom = document.createElement('div')
        ReactDOM.render(barCodeEl, barTempDom)
        const blob1 = new Blob([barTempDom.innerHTML], {type: 'image/svg+xml'});
        const url1 = URL.createObjectURL(blob1);
        el.querySelector('#bar-code').setAttribute('height', 30)
        el.querySelector('#bar-code').setAttribute('xlink:href', url1)

        // Handle description
        let description = data.description.match(/.{1,20}/g);
        el.querySelector('#top-des-1').firstChild.innerHTML = data.productName.match(/.{1,24}/g)[0]
        el.querySelector('#top-des-2').firstChild.innerHTML = description[0] ? description[0] : ""
    }

    componentDidUpdate = async () => {
        if (this.props.fullWidth !== this.state.fullWidth) {
            await this.setState({fullWidth : this.props.fullWidth})
        }
    }

    handleGetTemplates = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.get(`${url}/template`, config).then((res) => {
            if (res.status === 200) {
                this.setState({templateList : res.data})
            }
        })
    }

    handleProductList = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.get(`${url}/products`, config).then((response) => {
            if (response.status === 200) return response.data
        }).then((result) => {
            this.setState({productList : [...result].reverse(), numberOfPage : Math.ceil(result?.length/this.state.itemsPerPage)})
        })
    }

    chunkArray = (myArray, chunk_size) => {
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];
        
        for (index = 0; index < arrayLength; index += chunk_size) {
            let myChunk = myArray.slice(index, index+chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }
    
        return tempArray;
    }

    handleImageSrc = (data) => {
        
        let image = ""
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        let formData = new FormData()
        formData.append('photo_name', data.productPhotoPath)
        axios({
            url : `${url}/download`,
            data : formData,
            method : "POST",
            headers : config.headers,
            withCredentials : true,
            responseType : "blob"
        }).then((response) => {
            if (response.status === 200) {
                image = URL.createObjectURL(new Blob([response.data], {type: 'image/jpg'}));
                let el = ""
                if (this.state.imgFull) {
                    el = document.getElementById(`${data.productPhotoPath}-full`)
                } else {
                    el = document.getElementById(`${data.productPhotoPath}`)
                }
                
                if (el) el.src = image
            }
        })
    }

    handleImageCarClick = (data) => {
        data.productId = data.id
        let tempId = data.templateId
        let tempData = this.state.templateList.find(obj => obj.id == tempId)
        Object.assign(data, tempData)
        this.setState({
            fullWidth : true,
            fullWidthRenderData : data
        })
        this.handleDownloadStyle(data)
    }

    handleProductRender = () => {
        let data = this.state.productList
        let dataChunk = this.chunkArray(data, this.state.itemsPerPage)
        let renderData = dataChunk[this.state.page - 1]
        let cardWidth = (this.productContainer.current?.offsetWidth/6) -20
        let cardHeight = this.productContainer.current?.offsetHeight/4 -10
        if (renderData && this.productContainer.current?.offsetWidth) {
            return (
                renderData.map((k,i) => {
                    return (
                        <Link to={`/product/${k.id}`} >
                            <Card onClick={() => this.handleImageCarClick(k)} style={{width:cardWidth , padding:10, cursor:'pointer'}}>
                                <img id={k.productPhotoPath} onLoad={this.handleImageSrc(k)} style={{width:'inherit', height:'inherit', borderRadius:5}} />
                            </Card>
                        </Link>
                    )
                })
            )
        }
    }


    handleRangeSelect = (ranges) => {
        this.setState({selectionRange : [ranges.selection]})
        document.getElementsByClassName("rdrMonthAndYearWrapper")[0].classList.remove("flexImp") 
        document.getElementsByClassName("rdrMonths")[0].classList.remove("flexImp")
        
    }

    handleFilter = () => {
        let data = {
            template_name : this.state.templateList[this.state.template]?.name,
            batch_code : this.state.batchCode
        }
        if (this.state.selectionRange[0].startDate !== "") {
            data.start_date = this.state.selectionRange[0].startDate.setHours(0,0,0,0)/1000
            data.end_date = this.state.selectionRange[0].endDate.setHours(23,59,59,0)/1000
        }

        if (data['template_name'] === "") delete data['template_name']
        if (data['batch_code'] === "") delete data['batch_code']
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.post(`${url}/products`, data, config).then((response) => {
            if (response.status === 200) return response.data
        }).then((result) => {
            if (result.status) {
                if (result.products.length === 0) {
                    this.setState({alert : true, alertMsg : "No product Found!!", severity: "error"})
                }
                this.setState({productList : [...result.products].reverse(), numberOfPage : Math.ceil(result.products?.length/this.state.itemsPerPage)})
            }
            
        })
    }

    handlePageNavButton = (dir) => {
        let data = this.state.productList
        let selectProduct = this.state.fullWidthRenderData
        let selectIndex = data.findIndex(prod => prod.barCode == selectProduct.barCode)
        let predNext = data[selectIndex + 1]
        let predPrev = data[selectIndex - 1]
        if (dir === 'next' && predNext !== undefined) this.handleImageCarClick(predNext)
        if (dir === 'back' && predPrev !== undefined) this.handleImageCarClick(predPrev)
    }


    handlePrint = async () => {
        let printableDiv = document.getElementById("render-style")
        printableDiv.style.width = "100%"
        printableDiv = printableDiv.outerHTML

        const canvas = document.querySelector('canvas');

        const ctx = canvas.getContext('2d');
        const v = await Canvg.from(ctx, printableDiv);

        await v.render();
        
        var canva = document.getElementById('canvas')
        
        let png = await canvas.toDataURL("image/png");
        canvas.style.width = "1056px";
        canvas.style.height = "1416px";
        // var a = window.open('', '', 'height=1416, width=1056');
        // a.document.write('<html >');
        // a.document.write('<body >');
        // // a.document.write('<img  src="'+png+'"/>');
        // a.document.write('</body></html>');
        // a.document.close();

        // setTimeout(function () { // necessary for Chrome
        //     a.print();
        //     a.close();
        // }, 100);

        // Create a fake iframe
        const iframe = document.createElement('iframe');

        // Make it hidden
        iframe.style.height = 0;
        iframe.style.visibility = 'hidden';
        iframe.style.width = 0;

        // Set the iframe's source
        iframe.setAttribute('srcdoc', '<html><body></body></html>')

        document.body.appendChild(iframe);

        iframe.addEventListener('load', function () {
            
            // Clone the image
            const image = document.createElement('img')
            image.src = png
            image.style.maxWidth = '100%';
        
            // Append the image to the iframe's body
            const body = iframe.contentDocument.body;
            body.style.textAlign = 'center';
            body.appendChild(image);

            image.addEventListener('load', function() {
                // Invoke the print when the image is ready
                iframe.contentWindow.print();
            });
        });

    }

    handleDeleteProduct = (param) => {
        this.setState({open : true, label : 'Delete Product', component : <DeleteProduct data={param} handleClose={this.handleClose} />})
    }

    handleEditProduct = (param) => {
        this.setState({open : true, label : 'Edit Product', component : <EditProduct data={param} template={this.state.templateList} handleClose={this.handleClose} />})
    }

    handleClose = () => {
        this.setState({open : false, label : "", fullWidthRenderData : {}, fullWidth : false})
        this.handleProductList()
    }

    handleDialogClose = () => {
        this.handleProductList()
        this.setState({open : false, label : ""})

    }

    handleEditClose = () => {
        this.setState({open : false, label : ""})
        this.handleProductList()
    }

    handleTodayFilter = () => {
        let data = {}
        data.start_date = (new Date()).setHours(0,0,0,0)/1000
        let end_date = (new Date())
        end_date.setDate(end_date.getDate() + 1)
        data.end_date = end_date.setHours(23,59,59,0)/1000

        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.post(`${url}/products`, data, config).then((response) => {
            if (response.status === 200) return response.data
        }).then((result) => {
            if (result.status) {
                if (result.products.length === 0) {
                    this.setState({alert : true, alertMsg : "No product Found!!", severity: "error"})
                }
                this.setState({productList : [...result.products].reverse(), page:1, numberOfPage : Math.ceil(result.products?.length/this.state.itemsPerPage)})
            }
            
        })
    }

    render() {
        if (Object.keys(this.state.fullWidthRenderData).length === 0 && this.state.fullWidth) {
            if (this.props.role === "Admin") {
                return (
                    <Navigate to='/' />
                )
            } else {
                return (
                    <Navigate to='/product' />
                )
            }
            
        } else if (!this.state.fullWidth) {
            return (
                <Card ref={this.productContainer} style={{display:'flex', flex:1, padding:20, flexDirection:'column'}}>
                    
                    <div style={{display:'flex', flexDirection:'column', flex:0.3, gap:40}}>
                        <Typography>Products</Typography>
                        <div style={{display:'flex', flex:1, gap:60, marginLeft:50, alignItems:'center'}}>
                            
                            <div style={{display:'flex', gap:60, flexDirection:'column', flex:1}}>
                                <div style={{display:'flex', flexDirection:'column', gap:10}}>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', gap:50}}>
                                        {/* <InputLabel id="demo-simple-select-label">Template</InputLabel> */}
                                        <Typography style={{display:'flex', justifyContent:'flex-end'}} >Select Template</Typography>
                                        <Select
                                            value={this.state.template}
                                            style={{width:256}}
                                            // labelId="demo-simple-select-label"
                                            onChange={(e) => this.setState({template : e.target.value})}
                                        >
                                            {this.state.templateList.map((k,i) => 
                                                <MenuItem key={k.name} value={i}>{k.name}</MenuItem>
                                            )}
                                        </Select>
                                    </div>
                                    <Typography align={'center'}>OR</Typography>
                                    <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', gap:50}}>
                                        <Typography style={{display:'flex', justifyContent:'flex-end'}}>S/N</Typography>
                                        <TextField 
                                            value={this.state.batchCode}  
                                            variant="outlined" 
                                            placeholder="Enter S/N"
                                            style={{width:256}}
                                            // label="Batch Code" 
                                            onChange={(e) => this.setState({batchCode : e.target.value})} />
                                    </div>
                                </div>
                                
                            </div>
                            <div style={{display:'flex', flexDirection:'column', gap:20, flex:1}}>
                                <Typography>Select Date Range</Typography>
                                <RangeDatePicker
                                    startDate={this.state.selectionRange[0].startDate}
                                    endDate={this.state.selectionRange[0].endDate}
                                    onChange={(startDate, endDate) => {
                                        let data = [{
                                            startDate : startDate,
                                            endDate : endDate
                                        }]
                                        this.setState({selectionRange : data})
                                    }}
                                    minDate={new Date(2000, 0, 1)}
                                    maxDate={new Date(2100, 0, 1)}
                                    dateFormat="DD MMM YYYY"
                                    monthFormat="MMM YYYY"
                                    startDatePlaceholder="Start Date"
                                    endDatePlaceholder="End Date"
                                    disabled={false}
                                    className="my-own-class-name"
                                    startWeekDay="monday"
                                    />
                                <div style={{display:'flex', gap:80}}>
                                    <Button variant="contained" style={{flex:1}} onClick={this.handleFilter} endIcon={<ArrowRightAltIcon />}>Filter</Button>
                                    <Button variant="contained" style={{flex:1}} onClick={() => {
                                        this.setState({
                                            template : "",
                                            batchCode : "",
                                            selectionRange : [{
                                                startDate: "",
                                                endDate: "",
                                            }]})
                                        this.handleProductList()}
                                        } >Clear Filter</Button>
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', gap:20, flex:1}}>
                                <Button variant="contained" style={{flex:1}} onClick={this.handleTodayFilter} endIcon={<ArrowRightAltIcon />}>Today</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{flex:0.7, marginTop:30}}>
                        <div style={{ display:'flex', flexWrap:'wrap', gap:20, justifyContent:'center', alignItems:'center'}}>
                            {this.handleProductRender()}
                        </div>
                    </div>
                    
                    <Divider />
                    <Box component="span" style={{display:'flex', marginTop:5, justifyContent:'center'}}>
                        <Pagination
                            count={this.state.numberOfPage}
                            page={this.state.page}
                            onChange={(event, value) => {
                                this.setState({page : value})
                            }}
                            defaultPage={1}
                            color="primary"
                            size="small"
                            showFirstButton
                            showLastButton
                        />
                    </Box>
                    <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.setState({alert : false})}>
                        <Alert elevation={6} onClose={() => this.setState({alert : false})} severity={this.state.severity}>
                            {this.state.alertMsg}
                        </Alert>
                    </Snackbar>
                </Card>
            )
        }else {
            return (
                <Card ref={this.productContainer} style={{display:'flex', flex:1, padding:20, flexDirection:'column'}}>
                    <div style={{display:'flex', flexDirection:'column', flex:0.3, gap:40}}>
                        <Typography>Products {">"} {this.state.fullWidthRenderData.barCode} {">"} Details</Typography>
                        <div style={{flex:1, display:'flex'}}>
                            <div style={{flex:0.1, justifyContent:'center', alignItems:'center', display:'flex'}}>
                                <Button onClick={() => this.handlePageNavButton('back')}  style={{backgroundColor:'#0e70cf'}}>
                                    <ArrowCircleLeftRoundedIcon fontSize="medium" style={{color:'white'}} />
                                </Button>
                            </div>
                            <div style={{flex:0.8, display:'flex', gap:20, flexDirection:'column'}}>
                                <div style={{display:'flex', flexDirection:'column', gap:10}}>
                                    <div style={{display:'flex',  alignItems:'center'}}>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>S/N</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.batchCode} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                        
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>QR Code</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.qrCode} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display:'flex',  alignItems:'center'}}>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Name</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.productName} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Bar Code</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.barCode} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Description</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.description} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Gross Weight</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.grossWeight} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Measurements</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.measurements} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Template</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.name} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Label Date</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={(new Date(this.state.fullWidthRenderData.entryTime*1000)).toLocaleDateString()} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', flex:1, gap:80, alignItems: 'center'}}>
                                            <Typography textAlign="justify" fontWeight="500" fontSize="1.2rem" flex={0.4}>Made In</Typography>
                                            <div style={{flex:1}}>
                                                <TextField 
                                                    disabled
                                                    value={this.state.fullWidthRenderData.madeIn} 
                                                    variant="outlined" 
                                                    onChange={(e) => this.setState({name : e.target.value})} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{display:'flex', gap:40}}>
                                    <Card onClick={() => {
                                                this.setState({imgFull : true})}}
                                         style={{width:600 , height:380, padding:10,  display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                                        <Typography flex={1} style={{alignSelf:'flex-start'}}>Product Image</Typography>
                                        <div 
                                            onClick={() => {
                                                this.setState({imgFull : true})}}
                                            style={{width:28, height:28, backgroundColor:"white", justifyContent:'center', cursor:'pointer',
                                                 alignItems:'center', borderRadius:5,
                                                 position:'absolute', marginTop:34, marginRight:4, display:'flex',}}>
                                            <AspectRatioIcon />
                                        </div>
                                        <img id={this.state.fullWidthRenderData.productPhotoPath} onLoad={this.handleImageSrc(this.state.fullWidthRenderData)} style={{width:'inherit', height:350, borderRadius:5}} />
                                    </Card>
                                    <Card style={{width:250 , padding:10, cursor:'pointer'}}>
                                        <Typography>Label Image</Typography>
                                        <div style={{width:250}} id="render-style" className="render-temp"></div>
                                    </Card>
                                    <div style={{display:'flex', flexDirection:'column', gap:15}}>
                                        <Button onClick={this.handlePrint} style={{backgroundColor:'#0e70cf', width:150, maxHeight:54, color:'white', alignSelf:'center', padding:"10px 15px"}}>
                                            Print
                                        </Button>
                                        <Button onClick={() => this.handleEditProduct(this.state.fullWidthRenderData)} style={{backgroundColor:'#0e70cf', width:150, maxHeight:54, color:'white', alignSelf:'center', padding:"10px 15px"}}>
                                            Edit
                                        </Button>
                                        {this.props.role === "Admin" ?
                                        <Button onClick={() => this.handleDeleteProduct(this.state.fullWidthRenderData)} style={{backgroundColor:'#0e70cf', width:150, maxHeight:54, color:'white', alignSelf:'center', padding:"10px 15px"}}>
                                            Delete
                                        </Button> : null}
                                    </div>
                                    
                                </div>
                            </div>
                            <div style={{flex:0.1, justifyContent:'center', alignItems:'center', display:'flex'}}>

                                
                                <Button onClick={() => this.handlePageNavButton('next')}  style={{backgroundColor:'#0e70cf'}} >
                                    <ArrowCircleRightRoundedIcon fontSize="medium" style={{color:'white'}} />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Dialog
                        fullWidth
                        maxWidth={"lg"}
                        onKeyDown={(event) => {
                            if (event.key === "ArrowLeft") {
                                this.handlePageNavButton('back')
                            } else if (event.key === "ArrowRight") {
                                this.handlePageNavButton('next')
                            }
                        }}
                        PaperProps={{
                            style: {
                                flexDirection: "row",
                                alignItems : "center",
                                gap:20,
                                backgroundColor : "rgba(255, 255, 255, 0)",
                                boxShadow : "none"
                            }
                        }}
                        
                        open={this.state.imgFull}
                        onClose={() => {this.setState({imgFull : false})}}
                    >
                            <Button onClick={() => this.handlePageNavButton('back')}  style={{backgroundColor:'#0e70cf', height:50}} >
                                <ArrowCircleLeftRoundedIcon fontSize="medium" style={{color:'white'}} />
                            </Button>
                            <img id={`${this.state.fullWidthRenderData.productPhotoPath}-full`} onLoad={this.handleImageSrc(this.state.fullWidthRenderData)} style={{width:'auto', borderRadius:5, flex:1}} />
                            <Button onClick={() => this.handlePageNavButton('next')}  style={{backgroundColor:'#0e70cf', height:50}} >
                                <ArrowCircleRightRoundedIcon fontSize="medium" style={{color:'white'}} />
                            </Button>
                    </Dialog>
                    {/* Open Dialog Window */}
                    <Dialog
                        open={this.state.open}
                        maxWidth = {"sm"}
                        onClose={this.handleDialogClose}
                        fullWidth={true}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle
                            id="form-dialog-title"
                            disableTypography
                            style={{ background: '#585F6E', color: "white", display:'flex', justifyContent:'space-between', alignItems:'center' }}
                            >
                            <label style={{color:'white'}}>{this.state.label}</label>
                            <IconButton size="small" onClick={this.handleDialogClose} > <CloseIcon style={{color:'white'}} /></IconButton>
                        </DialogTitle>
                        <DialogContent style={{maxHeight:'65vh'}}>
                            {this.state.component}
                        </DialogContent>
                    </Dialog>
                    <canvas id="canvas" style={{display:"none"}} width="1056" height="1416"></canvas>
                </Card>
            )
        }
    }
}

export default  Products