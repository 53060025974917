import { AppBar, Avatar, Hidden, IconButton, List, ListItemIcon, SvgIcon, Typography, Card, ListItem, Divider, Drawer} from "@mui/material";
import logo from '../../assests/logo.png'
import React from "react";
import { Menu as MenuIcon, PieChart } from 'react-feather';
import { Link, NavLink, Outlet } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box } from "@mui/system";
import {url} from '../../globalurl'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import {
    Briefcase as BriefcaseIcon,
    Calendar as CalendarIcon,
    ShoppingCart as ShoppingCartIcon,
    Folder as FolderIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    UserPlus as UserPlusIcon,
    AlertCircle as AlertCircleIcon,
    Trello as TrelloIcon,
    User as UserIcon,
    Layout as LayoutIcon,
    Edit as EditIcon,
    DollarSign as DollarSignIcon,
    Mail as MailIcon,
    MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    Share2 as ShareIcon,
    Users as UsersIcon
} from 'react-feather';
import { withStyles } from "@mui/styles";
import axios from "axios";


const useStyles = (theme) => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        display: 'flex',
        flex: '1'
    }
})



class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false,
            anchorEl: null,
            openNav: false,
            userPhoto: null,
            userName : "",
            role : ''
        }
    }

    aDrawerItem = [
        {
            name : "Products",
            icon : <BriefcaseIcon size="1rem" />,
            url : "/"
        },
        {
            name : "Templates",
            icon : <TrelloIcon size="1rem" />,
            url : "/template"
        },
        {
            name : "Users",
            icon : <UserIcon size="1rem" />,
            url : "/user"
        },
        {
            name : "Styles",
            icon : <UserIcon size="1rem" />,
            url : "/style"
        }
    ]
    
    oDrawerItem = [
        {
            name : "Home",
            icon : <PieChartIcon size="1rem" />,
            url : '/'
        },
        {
            name : "Products",
            icon : <BriefcaseIcon size="1rem" />,
            url : "/product"
        }
    ]

    componentDidMount = () => {
        this.handleCheckRole()     // uncomment here
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null, menuOpen: false })
    }

    handleCheckRole = async () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        await axios.get(`${url}/role`, config).then((res) => {
            console.log(res)
            if (res.status === 200) {
                return res.data.role
            } else {
                this.props.setIsLogged(false)
                this.setState({error : "something went wrong"})         // Add logout to refresh token
            }
        }).then((result) => {
            if (result === 0) {
                this.setState({role : 'Admin'})
            } else if (result === 1) {
                this.setState({role : 'Operator'})
            } else {
                this.setState({error : "something went wrong"})         // add a logout
            }
        })
    }



    handleDrawer = () => {
        const {classes} = this.props
        return (
            <Box
                height="100%"
                display="flex"
                flexDirection="column"
            >
                <PerfectScrollbar options={{ suppressScrollX: true }} style={{ height: '100%' }}>
                    <Hidden lgUp>
                        <Box
                            p={2}
                            display="flex"
                            justifyContent="center"
                        >
                            <Link to="/">
                                <logo />
                            </Link>
                        </Box>
                    </Hidden>
                    <Box p={2}>
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Avatar
                                alt="User"
                                className={classes.avatar}
                                src={this.state.userPhoto}
                            />
                        </Box>
                        <Box
                            mt={2}
                            textAlign="center"
                        >
                            {localStorage.getItem("piarUser")}
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                            Role {this.state.role}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        {this.state.role === 'Admin' ? 
                            <List>
                                {this.aDrawerItem.map((k,i) => 
                                    <NavLink key={k.name} exact
                                         
                                        style={{ textDecoration: 'none', color: '#adb0bb', }}  to={k.url}>
                                        <ListItem button key={2}>
                                            <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}>{k.icon}</ListItemIcon>
                                            <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>{k.name}</Typography>
                                        </ListItem>
                                    </NavLink>
                                )}
                            </List>
                             :
                            <List >
                                {this.oDrawerItem.map((k,i) => 
                                    <NavLink key={k.name} exact style={{ textDecoration: 'none', color: '#adb0bb', }}  to={k.url}>
                                        <ListItem button key={2}>
                                            <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}>{k.icon}</ListItemIcon>
                                            <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>{k.name}</Typography>
                                        </ListItem>
                                    </NavLink>
                                )}
                            </List>
                            }                        
                    </Box>

                </PerfectScrollbar>
                <Card variant='outlined' style={{ zIndex: 1200 }}>
                    <Box p={1}>
                        <NavLink to="" style={{ textDecoration: 'none', color: '#adb0bb' }} activeStyle={{
                            fontWeight: 500,
                            color: "#FDBA27"
                        }} onClick={this.handleLogout}>
                            <ListItem button key={2}>
                                <ListItemIcon style={{ color: 'inherit', minWidth: 25 }}><LogoutRoundedIcon fontSize={'1rem'} /></ListItemIcon>
                                <Typography style={{ fontWeight: 500, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: 0, lineHeight: 1.75, fontSize: '.875rem' }}>Log Out</Typography>
                            </ListItem>
                        </NavLink>
                    </Box>
                </Card>
            </Box>
        )
    }

    handleLogout = async () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        await axios.get(`${url}/logout`, config).then((res) => {
            if (res.status === 200) {
                localStorage.setItem("token", "")             
                this.props.setIsLogged(false)
            } else {
                this.setState({error : "something went wrong"})         // Add logout to refresh token
            }
        })
    }

    render() {
        const {classes} = this.props
        console.log(this.props)
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column'}}>
                <AppBar variant='elevation' position='sticky' style={{ display: "flex", flexDirection: "row", flex: 1, maxHeight: 64, zIndex: 1300, backgroundColor:'#292D36' }}>
                    <Hidden lgUp>
                        <IconButton
                            color="inherit"
                            onClick={() => this.setState({ openNav: true })}
                        >
                            <SvgIcon fontSize="small">
                                <MenuIcon />
                            </SvgIcon>
                        </IconButton>
                    </Hidden>
                    <Hidden mdDown>
                        <div style={{ margin: "0px 20px 0px 20px",  display:'flex', alignItems:'center' }}>
                            <Link to='/' style={{display:'flex', alignItems:'center', gap : 10}}><img alt='logo' src={logo} width={140} /></Link>
                        </div>
                    </Hidden>
                    <div style={{ display: 'flex', flex: 1 }} />
                </AppBar>
                <div style={{ display: 'flex', flex: 1 }}>
                    <div>
                        <Hidden lgUp>
                            <Drawer
                                anchor="left"
                                classes={{ paper: classes.mobileDrawer }}
                                onClose={() => this.setState({ openNav: false })}
                                open={this.state.openNav}
                                variant="temporary"
                            >
                                {this.handleDrawer()}
                            </Drawer>
                        </Hidden>
                        <Hidden mdDown>
                            <Drawer
                                anchor="left"
                                classes={{ paper: classes.desktopDrawer }}
                                open
                                variant="persistent"
                            >
                                {this.handleDrawer()}
                            </Drawer>
                        </Hidden>
                    </div>
                    <div className={classes.wrapper}>
                        <div className={classes.contentContainer}>
                            <div className={classes.content}>
                                <Box style={{ margin: 20, flex: 1, display: 'flex' }}>
                                    <Outlet />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default withStyles(useStyles)(Dashboard)