import logo from './logo.svg';
import './App.css';
import RenderRoutes from './routes';
import {
  ThemeProvider
} from '@mui/material';
import { StylesProvider, jssPreset } from '@mui/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import { createNewTheme } from './theme';
import useSettings from './hooks/useSettings';
import axios from 'axios';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const { settings } = useSettings();
  // axios.defaults.withCredentials = true
  const theme = createNewTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <RenderRoutes />
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
