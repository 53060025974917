import { Button, Card, Tooltip, Typography, IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import axios from "axios";
import React from "react";
import { DataGrid} from '@mui/x-data-grid';
import { url } from "../../../globalurl";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import AddNewUser from "./userComp/AddNewUser";
import EditUser from "./userComp/EditUser";
import DeleteUser from "./userComp/DeleteUser";


class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            userData : [],
            open : false,
            label : '',
            component : ""
        }
        this.columns = [
            { field: 'username', headerName: 'Username', flex : 0.15 },
            { field: 'name', headerName: 'Name', flex: 0.2 },
            { field: 'role', headerName: 'Role', flex: 0.15 },
            { field: 'creationDate', headerName: 'Created', flex: 0.15 },
            { field: 'createBy', headerName: 'Created By', flex: 0.15 },
            {
                field: "adsManagement",
                headerName: "Actions",
                flex: 0.2,
                renderCell: (params) => this.renderButton(params),
                editable: false,
            }
        ];
        
    }

    componentDidMount = () => {
        // let testData = [{"uID": 1, "username": "admin", "password": "234567", "name": "myname", "role": 0, "creationDate": 1638328617.0, "createBy": 0}, {"uID": 5, "username": "operator_2", "password": "operator", "name": "Operator_2", "role": 1, "creationDate": 1638523963.0, "createBy": 0}]
        // this.setState({userData : testData})
        this.handleGetUsers()   // uncomment here
    }

    handleGetUsers = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.get(`${url}/users`, config).then((response) => {
            if (response.status === 200) return response.data
        }).then((result) => {
            if (result?.status === false) {

            } else {
                this.setState({userData : result})
            }
        })
    }

    renderButton = (params) => {
        return (
            <div style={{display:'flex', gap:10}}>
                <Tooltip title="Edit User" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleEditButton(params)} aria-label="Edit" ><EditRoundedIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip>
                <Tooltip title="Delete User" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleDeleteButton(params)}  ><DeleteRoundedIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip> 
            </div>
        )
    }

    handleGridRows = () => {
        let uData = []  
        this.state.userData.length !== 0 && this.state.userData?.map((k,i) =>{
            k["id"] = k.uID
            let date = new Date(k.creationDate*1000)
            
            if (date.getTime() > 0) {
                k["creationDate"] = date.toLocaleString()
            }
            if (k.role == 0) {
                k.role = 'Admin'
            } else if (k.role == 1) {
                k.role = 'Operator'
            }
            uData[i] = k
        })
        return uData
    }

    handleClose = () => {
        this.setState({open : false, label : ""})
        this.handleGetUsers()
    }

    handleAddUser = () => {
        this.setState({open : true, label : "Add New User", component : <AddNewUser handleClose={this.handleClose} />})
    }

    handleEditButton = (param) => {
        this.setState({open : true, label : 'Edit User', component : <EditUser data={param} handleClose={this.handleClose} />})
    }

    handleDeleteButton = (param) => {
        this.setState({open : true, label : 'Delete User', component : <DeleteUser data={param} handleClose={this.handleClose} />})
    }


    render() {
        return (
            <Card style={{display:'flex', flex:1, padding:20, flexDirection:'column'}}>
                <div style={{display:'flex', height:'fit-content', justifyContent:'space-between', }}>
                    <Typography>Users List</Typography>
                    <Button onClick={this.handleAddUser} variant="contained" startIcon={<PersonAddIcon />}>Add New User</Button>
                </div>
                <div style={{paddingTop:40, flex:1}}>
                    <DataGrid 
                        rows={this.handleGridRows()} 
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                        columns={this.columns} />
                </div>
                {/* Open Dialog Window */}
                <Dialog
                    open={this.state.open}
                    maxWidth = {"sm"}
                    onClose={this.handleClose}
                    fullWidth={true}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        disableTypography
                        style={{ background: '#585F6E', color: "white", display:'flex', justifyContent:'space-between', alignItems:'center' }}
                        >
                        <label style={{color:'white'}}>{this.state.label}</label>
                        <IconButton size="small" onClick={this.handleClose} > <CloseIcon style={{color:'white'}} /></IconButton>
                    </DialogTitle>
                    <DialogContent style={{maxHeight:'65vh'}}>
                        {this.state.component}
                    </DialogContent>
                </Dialog>
            </Card>
        )
    }
}

export default Users