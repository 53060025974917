import { Button, Card, Tooltip, Typography, IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import axios from "axios";
import React from "react";
import { DataGrid} from '@mui/x-data-grid';
import { url } from "../../../globalurl";

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import CloseIcon from '@mui/icons-material/Close';

import AddNewStyle from "./styleComp/AddNewStyle";
import EditStyle from "./styleComp/EditStyle";
import ViewStyle from "./styleComp/ViewStyle";
import DeleteStyle from "./styleComp/DeleteStyle";

import {
    Trello as TrelloIcon,
} from 'react-feather';


class Styles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            styleData : [],
            open : false,
            label : '',
            component : ""
        }
        this.columns = [
            { field: 'styleName', headerName: 'Name', flex: 0.1 },
            { field: 'createdBy', headerName: 'Created By', flex: 0.1 },
            {
                field: "adsManagement",
                headerName: "Actions",
                flex: 0.1,
                renderCell: (params) => this.renderButton(params),
                editable: false,
            }
        ];
    }

    componentDidMount = () => {
        // let testData = [{"id": 2, "name": "Template 1", "description": "This is a Test Template", "measurements": "20x20x20cm", "grossWeight": "18,9gm", "madeIn": "EU", "style": 1, "ctnPcs": 8, "batchCode": 123456}]
        // this.setState({tempData : testData})
        this.handleGetStyles()
    }

    handleGetStyles = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        axios.get(`${url}/style`, config).then((response) => {
            if (response.status === 200) return response.data
        }).then((result) => {
            console.log(result)
            this.setState({styleData : result})
        })
    }

    renderButton = (params) => {
        return (
            <div style={{display:'flex', gap:10}}>
                <Tooltip title="View Style" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleViewButton(params)} aria-label="View" ><VisibilityRoundedIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip>
                <Tooltip title="Delete Style" placement="top">
                    <div>
                        <IconButton size="small" onClick={() => this.handleDeleteButton(params)}  ><DeleteRoundedIcon fontSize="small" /></IconButton> 
                    </div>
                </Tooltip>
            </div>
        )
    }

    handleClose = () => {
        this.setState({open : false, label : ""})
        this.handleGetStyles()
    }

    handleAddTemplate = () => {
        this.setState({open : true, label : "Add New Style", component : <AddNewStyle handleClose={this.handleClose} />})
    }

    handleViewButton = (param) => {
        this.setState({open : true, label : 'View Style', component : <ViewStyle data={param} handleClose={this.handleClose} />})
    }

    handleEditButton = (param) => {
        this.setState({open : true, label : 'Edit Style', component : <EditStyle data={param} handleClose={this.handleClose} />})
    }

    handleDeleteButton = (param) => {
        this.setState({open : true, label : 'Delete Style', component : <DeleteStyle data={param} handleClose={this.handleClose} />})
    }

    render() {
        return (
            <Card style={{display:'flex', flex:1, padding:20, flexDirection:'column'}}>
                <div style={{display:'flex', height:'fit-content', justifyContent:'space-between', }}>
                    <Typography>Style List</Typography>
                    <Button onClick={this.handleAddTemplate} variant="contained" startIcon={<TrelloIcon />}>Add New Style</Button>
                </div>
                <div style={{paddingTop:40, flex:1}}>
                    <DataGrid 
                        rows={this.state.styleData} 
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        disableSelectionOnClick
                        columns={this.columns} />
                </div>
                {/* Open Dialog Window */}
                <Dialog
                    open={this.state.open}
                    maxWidth = {"sm"}
                    onClose={this.handleClose}
                    fullWidth={true}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        disableTypography
                        style={{ background: '#585F6E', color: "white", display:'flex', justifyContent:'space-between', alignItems:'center' }}
                        >
                        <label style={{color:'white'}}>{this.state.label}</label>
                        <IconButton size="small" onClick={this.handleClose} > <CloseIcon style={{color:'white'}} /></IconButton>
                    </DialogTitle>
                    <DialogContent style={{maxHeight:'65vh'}}>
                        {this.state.component}
                    </DialogContent>
                </Dialog>
            </Card>
        )
    }
}

export default Styles