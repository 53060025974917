import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, {useState, useEffect} from "react";
import {HashRouter as Router, Route, Routes, Navigate  } from "react-router-dom";
import Login from "./components/auth/login";
import Operator from "./components/dashboard/components/Operator";
import Products from "./components/dashboard/components/Products";
import Styles from "./components/dashboard/components/Styles";
import Templates from "./components/dashboard/components/Templates";
import Users from "./components/dashboard/components/Users";
import Dashboard from "./components/dashboard/dashboard";
import { url } from "./globalurl";
import { createBrowserHistory } from 'history';

export default function RenderRoutes(props) {
    const [isLogged, setIsLogged] = useState(false)
    const [token, setToken] = useState(localStorage.getItem("token"))
    const [loader, setLoader] = useState(true)
    const [role, setRole] = useState('')

    useEffect(() => {
        handleCheckLogin()
    }, [])

    const handleCheckLogin = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        
        if (localStorage.getItem('token')) {
            axios.get(`${url}/login`, config).then((result) => {
                if(result.status === 200 ) {
                    return result.data
                } else {
                    setLoader(false)
                }
            }).then((res) => {
                (res.status) ? setIsLogged(true) : setIsLogged(false);
                handleCheckRole(config)
            })
        } else {
            setIsLogged(false)
            setLoader(false)
        }
        
    }

    const handleCheckRole = async (config) => {
        await axios.get(`${url}/role`, config).then((res) => {
            if (res.status === 200) {
                return res.data.role
            } else {
                // Add logout to refresh token
                setIsLogged(false)
                setLoader(false)
            }
        }).then((result) => {
            if (result === 0) {
                setRole('Admin')
                setLoader(false)
            } else if (result === 1) {
                setRole('Operator')
                setLoader(false)
            } else if (result === "no_sess") {
                setIsLogged(false)
                setLoader(false)
                // add a logout
            }
        })
    }

    if (loader) {
        return (
            <Box sx={{display : 'flex', flex : 1, justifyContent:'center', alignItems:'center'}}>
                <CircularProgress />
            </Box>
        )
    } else {
        return (
            <Router>
                <Routes>
                    <Route path="/" element={!isLogged ? <Navigate replace to="/login" /> : <Dashboard setIsLogged={setIsLogged} />} >
                        <Route path={role === 'Admin' ? "/" : "/product"} index={role === "Admin"} element={<Products role={role} fullWidth={false} />} />
                        <Route path="product/:productId" element={<Products role={role} fullWidth={true}/>} />
                        {role === "Admin" ? <Route path="/user" element={<Users />} /> : null}
                        {role === "Admin" ? <Route path="/template" element={<Templates />} /> : null}
                        <Route path={role === 'Operator' ? "/" : "/operator"} index={role === "Operator"} element={<Operator />} />
                        {role === "Admin" ? <Route path="/style" element={<Styles />} /> : null}
                    </Route>
                    <Route path="/login" element={!isLogged ? <Login setIsLogged={setIsLogged} setRole={setRole} /> : <Navigate replace to="/" />} />
                </Routes>
            </Router>
        )
    } 
}