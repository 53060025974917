import { TextField, Typography, Button } from "@mui/material";
import React from "react";
import {url} from '../../../../globalurl'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class EditTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            description : "",
            name : "",
            productName : "",
            measurements : "" ,
            grossWeight : "",
            madeIn : "",
            ctnPcs : "",
            batchCode : "",
            dataCode : "",
            itemCode : "",
            style : "",
            styleList : [],
            alert : false,
            alertMsg : null,
            severity : null,
        }        
    }

    componentDidMount = () => {
        // let testData = [{"id": 1, "styleName": "Style 1", "stylePath": "style1", "createdBy": "0"}]
        // this.setState({styleList : testData})
        this.handleGetStyles()
        this.setState({
            description : this.getter('description'),
            name : this.getter('name'),
            productName : this.getter('productName'),
            measurements : this.getter('measurements') ,
            grossWeight : this.getter('grossWeight'),
            madeIn : this.getter('madeIn'),
            ctnPcs : this.getter('ctnPcs'),
            batchCode : this.getter('batchCode'),
            dataCode : this.getter('dataCode'),
            itemCode : this.getter('itemCode'),
            style : this.getter('style'),
        })
        
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    handleGetStyles = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }

        axios.get(`${url}/style`, config).then((res) => {
            if (res.status === 200) {
                this.setState({styleList : res.data})
            }
        })

    }

    handleModifyTemplate = () => {
        if (this.state.name === "" || this.state.description === "" 
            || this.state.measurements === "" || this.state.grossWeight === "" || this.state.madeIn === "" || this.state.ctnPcs === "" 
            || this.state.batchCode === "" || this.state.itemCode === "" || this.state.style === "" || this.state.productName === "") {
            if (this.state.name === "") {
                this.setState({severity:'error', alertMsg:'Template Name is required', alert:true})
            } else if (this.state.productName === "") {
                this.setState({severity:'error', alertMsg:'Product Name is required', alert:true})
            } else if (this.state.description === "") {
                this.setState({severity:'error', alertMsg:'Description is required', alert:true})
            } else if (this.state.measurements === "") {
                this.setState({severity:'error', alertMsg:'Measurements is required', alert:true})
            } else if (this.state.grossWeight === "") {
                this.setState({severity:'error', alertMsg:'Gross Weight is required', alert:true})
            } else if (this.state.madeIn === "") {
                this.setState({severity:'error', alertMsg:'Made In is required', alert:true})
            } else if (this.state.ctnPcs === "") {
                this.setState({severity:'error', alertMsg:'CTN/PCS is required', alert:true})
            } else if (this.state.batchCode === "") {
                this.setState({severity:'error', alertMsg:'S/N is required', alert:true})
            } else if (this.state.dataCode === "") {
                this.setState({severity:'error', alertMsg:'Data Code is required', alert:true})
            } else if (this.state.itemCode === "") {
                this.setState({severity:'error', alertMsg:'Item Code is required', alert:true})
            } else if (this.state.style === "") {
                this.setState({severity:'error', alertMsg:'Style is required', alert:true})
            }
        } else {
            let config = {
                headers : {
                    "Content-type": "Application/json",
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`
                },
                withCredentials : true
            }
            let data = {
                id : this.state.data.id,
                description : this.state.description,
                name : this.state.name,
                product_name : this.state.productName,
                measurements : this.state.measurements ,
                gross_weight : this.state.grossWeight,
                made_in : this.state.madeIn,
                ctn_pcs : this.state.ctnPcs,
                batch_code : this.state.batchCode,
                data_code : this.state.dataCode,
                item_code : this.state.itemCode,
                style : this.state.style,
            }
            axios.post(`${url}/template/modify`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({severity:'success', alertMsg:'Template Modified Successfully', alert:true})
                        this.props.handleClose()
                    } else {
                        this.setState({severity:'error', alertMsg:'error encountered', alert:true})
                    }
                }
            })
        }
    }

    handleClose = () => {
        this.setState({alert : false})
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30}}>
                <Typography>Template Details</Typography>
                <div style={{display:'flex', gap : 18}}>
                    <TextField 
                        value={this.state.name} 
                        fullWidth 
                        variant="outlined" 
                        label="Name" 
                        onChange={(e) => this.setState({name : e.target.value})} />
                    <TextField 
                        value={this.state.productName} 
                        fullWidth 
                        variant="outlined" 
                        label="Product Name" 
                        onChange={(e) => this.setState({productName : e.target.value})} />
                </div>
                <TextField 
                    value={this.state.description} 
                    fullWidth 
                    variant="outlined" 
                    label="Description" 
                    onChange={(e) => this.setState({description : e.target.value})} />
                <div style={{display:'flex', gap : 18}}>
                    <TextField 
                        value={this.state.measurements} 
                        fullWidth 
                        variant="outlined" 
                        label="Measurements" 
                        onChange={(e) => this.setState({measurements : e.target.value})} />
                    <TextField 
                        value={this.state.grossWeight} 
                        fullWidth 
                        variant="outlined" 
                        label="Gross Weight" 
                        onChange={(e) => this.setState({grossWeight : e.target.value})} />
                </div>
                <div style={{display:'flex', gap : 18}}>
                    <TextField 
                        value={this.state.madeIn} 
                        fullWidth 
                        variant="outlined" 
                        label="Made In" 
                        onChange={(e) => this.setState({madeIn : e.target.value})} />
                    <TextField 
                        value={this.state.ctnPcs} 
                        fullWidth 
                        variant="outlined" 
                        label="CTN/PCS" 
                        onChange={(e) => this.setState({ctnPcs : e.target.value})} />
                </div>
                <div style={{display:'flex', gap : 18}}>
                    <TextField 
                        value={this.state.batchCode} 
                        fullWidth 
                        variant="outlined" 
                        label="S/N" 
                        onChange={(e) => this.setState({batchCode : e.target.value})} />
                    <TextField 
                        value={this.state.itemCode} 
                        fullWidth 
                        variant="outlined" 
                        label="Item Code" 
                        onChange={(e) => this.setState({itemCode : e.target.value})} />
                </div>
                <div style={{display:'flex', gap : 18}}>
                    <TextField 
                        value={this.state.dataCode} 
                        fullWidth 
                        variant="outlined" 
                        label="Data Code" 
                        onChange={(e) => this.setState({dataCode : e.target.value})} />
                </div>
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Style</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.style}
                        label="Style"
                        onChange={(e) => this.setState({style : e.target.value})}
                    >
                        {this.state.styleList.map((k,i) => 
                            <MenuItem key={k.styleName} value={k.id}>{k.styleName}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Button 
                    fullWidth 
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop : 10/4,
                        height : 48
                    }}  
                    focusRipple 
                    onClick={this.handleModifyTemplate}
                    >SAVE</Button>
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default EditTemplate