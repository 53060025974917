import React from "react";
import { Typography, Button } from "@mui/material";
import {url} from '../../../../globalurl'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";

class DeleteUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            username : '',
            name : '',
            alert : false,
            alertMsg : null,
            severity : null,
        }
        
    }

    componentDidMount = () => {
        this.setState({username : this.getter('username'), name : this.getter('name')})
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    handleDeleteUser = () => {
        let config = {
            headers : {
                "Content-type": "Application/json",
                "Authorization" : `Bearer ${localStorage.getItem('token')}`
            },
            withCredentials : true
        }
        let data = {
            username : this.state.username,
        }
        axios.post(`${url}/users/delete`, data, config).then((res) => {
            if (res.status === 200) {
                if (res.data.status) {
                    this.setState({severity:'success', alertMsg:'User Deleted Successfully', alert:true})
                    this.props.handleClose()
                } else {
                    this.setState({severity:'error', alertMsg:'User already exist', alert:true})
                }
            }
        })
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30}}>
                <Typography>Confirm <span style={{color : 'red'}}>Deletion</span> of user <b>{this.state.name}</b></Typography>
                
                <Button 
                    fullWidth 
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop : 10/4,
                        height : 48
                    }}  
                    focusRipple 
                    onClick={this.handleDeleteUser}
                    >Confirm</Button>
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default DeleteUser