import React from "react";
import { TextField, Typography, Button } from "@mui/material";
import {url} from '../../../../globalurl'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";


class EditUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            username : "",
            name : "",
            password : "",
            role : 0 ,
            alert : false,
            alertMsg : null,
            severity : null,
        }   
    }

    componentDidMount = () => {
        this.setState({username : this.getter('username'), name : this.getter('name'), role : this.getter('role') === 'Admin' ? 0 : 1})
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    handleModifyUser = () => {
        if (this.state.username === "" || this.state.name === "" ) {
            if (this.state.username === "") {
                this.setState({severity:'error', alertMsg:'Username is required', alert:true})
            } else if (this.state.name === "") {
                this.setState({severity:'error', alertMsg:'Name field is required', alert:true})
            }
        } else {
            let config = {
                headers : {
                    "Content-type": "Application/json",
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`
                },
                withCredentials : true
            }
            let data = {
                uid : this.state.data.id,
                username : this.state.username,
                name : this.state.name,
                role : this.state.role 
            }
            if (this.state.password !== "") data['password'] = this.state.password
            axios.post(`${url}/users/modify`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({severity:'success', alertMsg:'User Update Successfully', alert:true})
                        this.props.handleClose()
                    } else {
                        this.setState({severity:'error', alertMsg:'User already exist', alert:true})
                    }
                }
            })
        }
    }

    handleClose = () => {
        this.setState({alert : false})
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30}}>
                <Typography>Edit User Details</Typography>
                <TextField 
                    value={this.state.username} 
                    fullWidth 
                    variant="outlined" 
                    label="Username" 
                    disabled
                    onChange={(e) => this.setState({username : e.target.value})} />
                <TextField 
                    value={this.state.name} 
                    fullWidth 
                    variant="outlined" 
                    label="Name" 
                    onChange={(e) => this.setState({name : e.target.value})} />
                <TextField 
                    value={this.state.password} 
                    fullWidth 
                    variant="outlined" 
                    placeholder="Leave Empty to not change it"
                    label="Change Password" 
                    onChange={(e) => this.setState({password : e.target.value})} />
                <FormControl component="fieldset" color="secondary">
                    <FormLabel component="legend">Role</FormLabel>
                    <RadioGroup
                        aria-label="role"
                        value = {this.state.role}
                        style={{display:'flex', flexDirection:'row', flex:1, justifyContent:"space-between", alignItems:'center', marginTop:5}}
                        onChange = {(e) => this.setState({role : e.target.value })}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="0" style={{flex:1}} color="secondary" control={<Radio />} label="Admin" />
                        <FormControlLabel value="1" style={{flex:1}} control={<Radio />} label="Operator" />
                    </RadioGroup>
                </FormControl>
                <Button 
                    fullWidth 
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop : 10/4,
                        height : 48
                    }}  
                    focusRipple 
                    onClick={this.handleModifyUser}
                    >Save</Button>
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default EditUser