import { TextField, Typography, Button } from "@mui/material";
import React from "react";
import {url} from '../../../../globalurl'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class EditProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            templateList : this.props.template,
            template : "",
            qrCode : "",
            barCode : "",
            alert : false,
            alertMsg : null,
            severity : null,
        }        
    }

    componentDidMount = () => {
        this.setState({qrCode : this.state.data.qrCode, barCode : this.state.data.barCode, template : this.state.data.templateId})
    }


    handleModifyProduct = () => {
        if (this.state.qrCode === "" || this.state.barCode === "" || this.state.template === "") {
            if (this.state.qrCode === "") {
                this.setState({severity:'error', alertMsg:'QR Code is required', alert:true})
            } else if (this.state.barCode === "") {
                this.setState({severity:'error', alertMsg:'Bar Code is required', alert:true})
            } else if (this.state.template === "") {
                this.setState({severity:'error', alertMsg:'Template is required', alert:true})
            } 
        } else {
            let config = {
                headers : {
                    "Content-type": "Application/json",
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`
                },
                withCredentials : true
            }
            let data = {
                id : this.state.data.productId,
                qr_code : this.state.qrCode,
                bar_code : this.state.barCode,
                template_id : this.state.template
            }
            axios.post(`${url}/products/modify`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({severity:'success', alertMsg:'Product Modified Successfully', alert:true})
                        this.props.handleClose()
                    } else {
                        this.setState({severity:'error', alertMsg:'error encountered', alert:true})
                    }
                }
            })
        }
    }

    handleClose = () => {
        this.setState({alert : false})
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30}}>
                <Typography>Product Details</Typography>
                <TextField 
                    value={this.state.qrCode} 
                    fullWidth 
                    variant="outlined" 
                    label="QR Code" 
                    onChange={(e) => this.setState({qrCode : e.target.value})} />
                <TextField 
                    value={this.state.barCode} 
                    fullWidth 
                    variant="outlined" 
                    label="Bar Code" 
                    onChange={(e) => this.setState({barCode : e.target.value})} />
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Style</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.template}
                            label="Template"
                            onChange={(e) => this.setState({template : e.target.value})}
                        >
                            {this.state.templateList.map((k,i) => 
                                <MenuItem key={k.name} value={k.id}>{k.name}</MenuItem>
                            )}
                    </Select>
                </FormControl>
                <Button 
                    fullWidth 
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop : 10/4,
                        height : 48
                    }}  
                    focusRipple 
                    onClick={this.handleModifyProduct}
                    >SAVE</Button>
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default EditProduct