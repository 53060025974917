import { Button, Card, TextField, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import logo from '../../assests/logo.png';
import {url} from '../../globalurl'

let sw = window.innerWidth

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username : "",
            password : ""
        }
    }

    handleLogin = () => {
        let data = {
            username : this.state.username,
            password : this.state.password
        }
        axios.post(`${url}/login`, data, {withCredentials : true}).then((result) => {
            if (result.status === 200) {
                return result.data
            }
        }).then(async (Response) => {
            if (Response.status) {
                localStorage.setItem("token", Response.token)
                localStorage.setItem('piarUser', this.state.username)
                let config = {
                    headers : {
                        "Access-Control-Allow-Origin" : "*",
                        "Content-type": "Application/json",
                        "Authorization" : `Bearer ${Response.token}`
                    },
                    withCredentials : true
                }
                this.handleCheckRole(config)       
                this.props.setIsLogged(true)

            } else {
                this.props.setIsLogged(false)
            }
        })
        
        
    }

    handleCheckRole = async (config) => {
        await axios.get(`${url}/role`, config).then((res) => {
            if (res.status === 200) {
                return res.data.role
            } else {
                console.log("Something went wrong")              // Add logout to refresh token
            }
        }).then((result) => {
            console.log(result)
            if (result === 0) {
                this.props.setRole('Admin')
            } else if (result === 1) {
                this.props.setRole('Operator')
            } else {
                console.log("Something went wrong")       // add a logout
            }
        })
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, justifyContent:"center", alignItems:'center'}}>
                <Card sx={{
                    minWidth : sw/4,
                    minHeight :400,
                    borderRadius: 15/4,
                    padding:10/4,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    
                }}>
                    <div style={{padding:20}}>
                        <img src={logo} width={sw/12}/>
                        <div style={{display:'flex', gap:18, flexDirection:"column"}}>
                            <Typography variant="h5" color="secondary">Login to your account</Typography>
                            <TextField 
                                value={this.state.username} 
                                fullWidth 
                                variant="outlined" 
                                label="Username" 
                                onChange={(e) => this.setState({username : e.target.value})} />
                            <TextField 
                                value={this.state.password} 
                                fullWidth 
                                variant="outlined" 
                                label="Password" 
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.handleLogin()
                                    }
                                }}
                                onChange={(e) => this.setState({password : e.target.value})}/>
                        </div>
                        <Button 
                            fullWidth 
                            variant="contained"
                            color="secondary"
                            sx={{
                                marginTop : 15/4,
                                height : 48
                            }}  
                            focusRipple 
                            onClick={this.handleLogin}
                            >Login</Button>
                    </div>
                    
                </Card>
            </div>
        )
    }
}

export default Login