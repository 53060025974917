import { TextField, Typography, Button } from "@mui/material";
import React from "react";
import {url} from '../../../../globalurl'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class EditStyle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data : this.props.data,
            description : "",
            name : "",
            file : "",
            img : "",
            alert : false,
            alertMsg : null,
            severity : null,
        }        
    }

    componentDidMount = () => {
        // let testData = [{"id": 1, "styleName": "Style 1", "stylePath": "style1", "createdBy": "0"}]
        // this.setState({styleList : testData})
        this.setState({
            description : this.getter('description'),
            name : this.getter('styleName'),
            path : this.getter('stylePath') ,
        })
        
    }

    getter = (field) => {
        return this.state.data.getValue(this.state.data.id, field)
    }

    handleModifyTemplate = () => {
        if (this.state.name === "" || this.state.description === "" 
            || this.state.measurements === "" || this.state.grossWeight === "" || this.state.madeIn === "" || this.state.ctnPcs === "" 
            || this.state.batchCode === "" || this.state.itemCode === "" || this.state.style === "") {
            if (this.state.name === "") {
                this.setState({severity:'error', alertMsg:'Template Name is required', alert:true})
            } else if (this.state.description === "") {
                this.setState({severity:'error', alertMsg:'Description is required', alert:true})
            } else if (this.state.measurements === "") {
                this.setState({severity:'error', alertMsg:'Measurements is required', alert:true})
            } else if (this.state.grossWeight === "") {
                this.setState({severity:'error', alertMsg:'Gross Weight is required', alert:true})
            } else if (this.state.madeIn === "") {
                this.setState({severity:'error', alertMsg:'Made In is required', alert:true})
            } else if (this.state.ctnPcs === "") {
                this.setState({severity:'error', alertMsg:'CTN/PCS is required', alert:true})
            } else if (this.state.batchCode === "") {
                this.setState({severity:'error', alertMsg:'Batch Code is required', alert:true})
            } else if (this.state.itemCode === "") {
                this.setState({severity:'error', alertMsg:'Item Code is required', alert:true})
            } else if (this.state.style === "") {
                this.setState({severity:'error', alertMsg:'Style is required', alert:true})
            }
        } else {
            let config = {
                headers : {
                    "Content-type": "Application/json",
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`
                },
                withCredentials : true
            }
            let data = {
                id : this.state.data.id,
                description : this.state.description,
                name : this.state.name,
                measurements : this.state.measurements ,
                gross_weight : this.state.grossWeight,
                made_in : this.state.madeIn,
                ctn_pcs : this.state.ctnPcs,
                batch_code : this.state.batchCode,
                item_code : this.state.itemCode,
                style : this.state.style,
            }
            axios.post(`${url}/template/modify`, data, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({severity:'success', alertMsg:'Template Modified Successfully', alert:true})
                        this.props.handleClose()
                    } else {
                        this.setState({severity:'error', alertMsg:'error encountered', alert:true})
                    }
                }
            })
        }
    }

    handleClose = () => {
        this.setState({alert : false})
    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30}}>
                <Typography>Style Details</Typography>
                <TextField 
                    value={this.state.name} 
                    fullWidth 
                    variant="outlined" 
                    label="Name" 
                    onChange={(e) => this.setState({name : e.target.value})} />
                <img src={this.state.img} width={200} />
                {this.state.img !== "" ? 
                    <Button 
                        fullWidth 
                        variant="contained"
                        color="secondary"
                        sx={{
                            marginTop : 10/4,
                            height : 48
                        }}  
                        focusRipple 
                        onClick={() => this.setState({img : ""})}
                        >Remove</Button> : null
                }
                {/* {this.state.img === "" ?
                    <ImageUploader
                        withIcon={false}
                        buttonText='Upload Style'
                        onChange={(event) => this.onDrop(event)}
                        imgExtension={['.svg']}
                        singleImage
                        maxFileSize={1048576}
                    /> : null } */}
                <Button 
                    fullWidth 
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop : 10/4,
                        height : 48
                    }}  
                    focusRipple 
                    onClick={this.handleModifyTemplate}
                    >SAVE</Button>
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default EditStyle