import { TextField, Typography, Button } from "@mui/material";
import React from "react";
import {url} from '../../../../globalurl'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";
import ImageUploader from "react-images-upload";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class AddNewStyle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name : "",
            file : "",
            alert : false,
            img : "",
            alertMsg : null,
            severity : null,
        }        
    }

    componentDidMount = () => {
        // let testData = [{"id": 1, "styleName": "Style 1", "stylePath": "style1", "createdBy": "0"}]
        // this.setState({styleList : testData})
        // this.handleGetStyles()
    }


    handleAddNewStyle = () => {
        if (this.state.name === "" || this.state.file === ""  ) {
            if (this.state.name === "") {
                this.setState({severity:'error', alertMsg:'Style Name is required', alert:true})
            } else if (this.state.file === "") {
                this.setState({severity:'error', alertMsg:'SVG File is required', alert:true})
            } 
        } else {
            let config = {
                headers : {
                    "Content-type": "Application/json",
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`
                },
                withCredentials : true
            }
            let formData = new FormData();
            formData.append("file", this.state.file )
            formData.append('name', this.state.name)
            axios.post(`${url}/style`, formData, config).then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.setState({severity:'success', alertMsg:'Style Added Successfully', alert:true})
                        this.props.handleClose()
                    } else {
                        this.setState({severity:'error', alertMsg:'Error Encountered, Please Try again', alert:true})
                    }
                }
            })
        }
    }

    handleClose = () => {
        this.setState({alert : false})
    }

    // Handle picture upload for Styles
    //  @param path : photo
    onDrop = (picture) => {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(picture[0])
        fileReader.onloadend = (e) => {
            this.setState({img : [fileReader.result], file : picture[0]})
        }

    }

    render() {
        return (
            <div style={{display:'flex', flex:1, flexDirection:'column', marginTop:30, gap:18, marginBottom:30}}>
                <Typography>Style Details</Typography>
                <TextField 
                    value={this.state.name} 
                    fullWidth 
                    variant="outlined" 
                    label="Name" 
                    onChange={(e) => this.setState({name : e.target.value})} />
                <img src={this.state.img} width={200} />
                {this.state.img !== "" ? 
                    <Button 
                        fullWidth 
                        variant="contained"
                        color="secondary"
                        sx={{
                            marginTop : 10/4,
                            height : 48
                        }}  
                        focusRipple 
                        onClick={() => this.setState({img : ""})}
                        >Remove</Button> : null
                }
                {this.state.img === "" ?
                    <ImageUploader
                        withIcon={false}
                        buttonText='Upload Style'
                        onChange={(event) => this.onDrop(event)}
                        imgExtension={['.svg']}
                        singleImage
                        maxFileSize={1048576}
                    /> : null }

                <Button 
                    fullWidth 
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginTop : 10/4,
                        height : 48
                    }}  
                    focusRipple 
                    onClick={this.handleAddNewStyle}
                    >Add</Button>
                <Snackbar open={this.state.alert} autoHideDuration={3000} anchorOrigin={{vertical : "bottom" , horizontal: "center"}} onClose={this.handleClose}>
                    <Alert elevation={6} onClose={this.handleClose} severity={this.state.severity}>
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default AddNewStyle